import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Toast from '../components/Toast';
import '../assets/login/css/main.css';
import '../assets/login/css/util.css';
import '../assets/login/fonts/iconic/css/material-design-iconic-font.min.css';

import { loginAction } from '../redux/actions/loginActions';
import { userLoadAction } from '../redux/actions/userActions';

const Login: React.FC = () => {
    const [credentials, SetCredentials] = useState({email: '', password: ''});
    const [ini, setIni] = useState(true);
    const [showPass, setShowPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const refUser = useRef<HTMLInputElement>(null)

    const SignIn = (data: any) => dispatch( loginAction(data) );
    const LoadUser = () => dispatch( userLoadAction() );

    const login = useSelector((state: any) => {
      return state.login;
    });

    useEffect(() => {
      console.log(login);
      if(refUser && refUser.current) {
        refUser.current.focus();
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[login]);

    if (login.validAccess === true) {
      console.log('Logeado');
      LoadUser();
      window.location.href = `/home`;
    }
    
    const LoginUser = (e: any) => {
      e.preventDefault();
      setLoading(true);

      if( credentials.email === '' && credentials.password === ''){
          setLoading(false);
          Toast.fire(
            {
                type: 'error',
                title: `Ingrese un usuario y/o contraseña`
            }
          )
          return;
      }

      SignIn(credentials)
      
      setTimeout(() => {
          setLoading(false);
      },1000);
    };

    const handleKeyPress = (e: any) => {
      if (ini) {
        setIni(false);
      }
      if (e.key === 'Enter'){
        LoginUser(e);
      }
    }


    if (loading) {
      console.log('Cargando');
    }

    return (
      <>
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <div className="login100-form validate-form">
                        <span className="login100-form-title p-b-48">
                            <div className="logo-pdv">PDV</div>
                            <div className="txt1">v0.0.3 02/19/2020</div>
                        </span>
                        <span className="login100-form-title p-b-26 welcome-pdv">
                            ¡Bienvenido!
                        </span>

                        <div className={`wrap-input100 validate-input ${(credentials.email.length === 0 && !ini)  ? 'alert-validate' : ''}`} data-validate = "Necesita ingresar un correo válido a@b.c">
                            <input 
                                className={`input100 ${credentials.email.length === 0 ? '' : 'has-val'}`}
                                type="text"
                                name="email"
                                ref={refUser}
                                required={true}
                                value={credentials.email}
                                onChange={e => SetCredentials({email: e.target.value, password: credentials.password})}
                                onKeyUp={handleKeyPress}
                            />
                            <span className="focus-input100" data-placeholder="Email"></span>
                        </div>

                        <div className={`wrap-input100 validate-input ${(credentials.password.length === 0 && !ini) ? 'alert-validate' : ''}`} data-validate = "Necesita ingresar su contraseña">
                            <span className="btn-show-pass">
                                <i className={`zmdi ${showPass ? 'zmdi-eye-off' : 'zmdi-eye'}`} onClick={e => setShowPass(!showPass)}></i>
                            </span>
                            <input 
                                className={`input100 ${credentials.password.length === 0 ? '' : 'has-val'}`}
                                type={`${showPass ? 'text' : 'password'}`}
                                name="pass"
                                required={true}
                                value={credentials.password}
                                onChange={e => SetCredentials({email: credentials.email, password: e.target.value})}
                                onKeyUp={handleKeyPress}                                
                            />
                            <span className="focus-input100" data-placeholder="Password"></span>
                        </div>

                        <div className="container-login100-form-btn">
                            <div className="wrap-login100-form-btn">
                                <div className="login100-form-bgbtn"></div>
                                <button className="login100-form-btn"  onClick={LoginUser}>
                                    Login
                                </button>
                            </div>
                        </div>

                        <div className="text-center p-t-115">
                            <span className="txt1">
                                ¿No recuerdas tu contraseña?
                            </span>
                            <br/>
                            <a className="txt2 p-l-10" href="/">
                                Recuperar contraseña
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="dropDownSelect1"></div>
      </>
    );
  };

  export default Login;
  