import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUserAction } from '../../redux/actions/userActions';
import Modal from 'react-responsive-modal';
import Toast from '../Toast';
import Loading from '../Loading';

const NewUser: React.FC<{modAdd: any, onCloseAdd: any, setModAdd: any}> = (props) => {
    const mClases = {
        modal: "card border-dark"
    }
    const [open, setOpen] = useState(false);
    const [rol, setRol] = useState('1');
    const [assingment, setAssingment] = useState('1');
    const [name, setName] = useState('');
    const [middle_name, setMiddleName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [terms, setTerms] = useState(false);
    const [process, setProcess] = useState(false);

    let Rols = [ 'ROOT', 'ADMIN', 'OWNER', 'MANAGER', 'PROMOTOR', 'DEALER', 'REPORTER' ];
    let Assignments = ['IDEASG', 'CAMALEON', 'LTTE WEB DEVELOPMENT'];

    const dispatch = useDispatch();
    const createUser = (userObj: any) => { dispatch( createUserAction(userObj) )};

    const user = useSelector((state: any) => {
        return state.user;
    });

    useEffect(() => {
        setOpen(props.modAdd);
        // code 
    },[props.modAdd])

    const SendNewUser = () => {
        setProcess(true);

        let userObj = {
            who: {
                id: user.perfil.user.id
            },
            email: email,
            id_rol: rol,
            id_assignment: assingment,
            name: name,
            middle_name: middle_name,
            last_name: last_name
        }


        if(name === '' || middle_name === '' || last_name === '') {
            Toast.fire(
                {
                    type: 'error',
                    title: 'Debe llenar todos los datos.'
                }
            );
            setProcess(false);
            return false;
        } else {
            if (!terms) {
                Toast.fire(
                    {
                        type: 'error',
                        title: 'Debe aceptar los terminos y condiciones.'
                    }
                );
                setProcess(false);
                return false;
            } else {
                createUser(userObj);
                const waitResult = setTimeout(() => {
                    setOpen(false);
                    props.setModAdd(false);
                    props.onCloseAdd();
                    setProcess(false);
                }, 2000);
        
                return () => waitResult;
            }
        }
    }

    const closeAdd = () => {
        setOpen(false);
        props.setModAdd(false);
        //props.onCloseAdd();

    }

    return (
        <Modal open={open} onClose={props.onCloseAdd} center classNames={mClases} showCloseIcon={false} closeOnOverlayClick={false}>
            { process ? (
            <>
                <div className="card-header">Crear Usuario</div>
                <br />
                <div className="card-body text-dark">
                    <Loading message="Procesando..."/>
                </div>
            </>
            ) :
            (<>
            <div className="card-header">Crear Usuario</div>
            <br />
            <div className="card-body text-dark">
                <div className="card-body text-dark">
                    <p className="card-text">A continuación llena los datos solicitados.</p>
                    <br /><br />
                    <div className="form-group">
                        <label> Correo Electrónico</label>
                        <input className="form-control" placeholder="Correo Electrónico" type="email" onChange={e => setEmail(e.target.value)} />
                    </div>

                    <div className="form-group">
                        <label > Rol</label>
                        <select className="form-control" onChange={e => setRol((e.target.value))}>
                            { Rols.map((rol: any, index: number) => {
                                return (
                                    <option key={index} value={index + 1}>
                                        {rol}
                                    </option>
                                    )
                                })

                            }
                        </select>
                        <label > Asignación</label>
                        <select className="form-control" onChange={e => setAssingment((e.target.value))}>
                            { Assignments.map((place: any, index: number) => {
                                return (
                                    <option key={index} value={index + 1}>
                                        {place}
                                    </option>
                                    )
                                })

                            }
                        </select>
                    </div>

                    <div className="form-section">
                        <span>Datos del contacto</span>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label > Nombre</label>
                                <input className="form-control" placeholder="Nombre" type="text" onChange={e => setName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label > Apellido Paterno</label>
                                <input className="form-control" placeholder="Apellido Paterno" type="text" onChange={e => setMiddleName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label > Apellido Materno</label>
                                <input className="form-control" placeholder="Apellido Paterno" type="text" onChange={e => setLastName(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="check-label">
                            <input type="checkbox" onChange={e => setTerms(e.target.checked)}/> 
                            Acepta términos y condiciones
                        </label>
                    </div>
                </div>
                <div className="content-box-footer col-md-12">
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn btn-pdv-process" onClick={SendNewUser}>
                                <i className="fa fa-save"></i> Guardar
                            </button>
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-pdv-cancel" onClick={closeAdd}>
                                <i className="fa fa-close"></i> Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </>)
            }
        </Modal>
    );
}

export default NewUser;
