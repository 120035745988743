/** LOGIN */
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR'; 

export const LOGIN_LOGOUT = 'LOGIN_LOGOUT';

/** USER */
export const USER_INIT    = 'USER_INIT';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_CREATED = 'USER_CREATED';
export const USER_DELETED = 'USER_DELETED';
export const USER_REJECTED = 'USER_REJECTED';
export const USER_ERROR   = 'USER_ERROR ';

/** USERS */
export const USERS_INIT = 'USERS_INIT';
export const USERS_LIST = 'USER_LIST';
export const USERS_ADD = 'USER_ADD';
export const USERS_SELECTED = 'USERS_SELECTED';
export const USERS_ERROR = 'USERS_ERROR';

/** SIDEBAR */
export const SIDE_HIDE = 'SIDE_HIDE';
export const SIDE_SHOW = 'SIDE_SHOW';
export const SIDE_ERROR = 'SIDE_ERROR';
