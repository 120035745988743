import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserList } from '../redux/actions/usersActions'
import ListUsers from '../components/ListUsers';
import Spinner from '../components/Spinner';

const Register: React.FC = () => {
    const [tabSel, setTabSel] = useState('reg-user')

    const users = useSelector((state: any) => {
        return state.users;
    })

    const dispatch = useDispatch();
    const checkUsers = () => dispatch(getUserList());

    useEffect(() => {
        checkUsers();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    [users.list.list]
    )

    const ActiveTab = (tab: any) => {
        setTabSel(tab.target.id);
        switch(tab.target.id) {
            case 'reg-user':
                checkUsers();
                return;
            case 'reg-complex':
            case 'reg-brand':
            case 'reg-permissions':
        }
        // console.log(tab.target.id);
    }

  return (
    <div className="content">
        <div className="panel">
            <div className="content-header no-mg-top">
                <i className="fa fa-newspaper-o"></i>
                <div className="content-header-title">Registro</div>

                <nav className="nav nav-pills nav-fill">
                    <div onClick={ActiveTab} id="reg-user" className={`btn nav-item nav-link ${tabSel === 'reg-user' ? 'active': ''}`} >Usuario</div>
                    <div onClick={ActiveTab} id="reg-complex" className={`btn nav-item nav-link ${tabSel === 'reg-complex' ? 'active': ''}`} >Complejo</div>
                    <div onClick={ActiveTab} id="reg-brand" className={`btn nav-item nav-link ${tabSel === 'reg-brand' ? 'active': ''}`} >Marca</div>
                    <div onClick={ActiveTab} id="reg-permissions" className={`btn nav-item nav-link ${tabSel === 'reg-permissions' ? 'active': ''}`} >Permisos</div>
                </nav>
            </div>
            {
                tabSel === 'reg-user' ? 
                    users.loading ? <Spinner /> 
                    : <ListUsers list={users} /> 
                    : null
            }
        </div>
    </div>
  );
};

export default Register;

