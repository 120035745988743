import { IonContent, IonHeader, IonPage } from '@ionic/react';
import { Route, Switch, /* Redirect, useHistory*/ } from "react-router-dom";
import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch, /* useDispatch */ } from 'react-redux';

import SideBar from '../components/SideBar';
import ToolBar from '../components/ToolBar';

import NotFound from './404';
import Home from './Home';
import BreadCrumb from '../components/BreadCrumb';

import { INI_MENU } from "../utils/ini";
import { _MENU } from "../utils/types";
import Pos from './Pos';
import AddressBook from './AdressBook';
import Products from './Products';
import Archives from './Archives';
import Camps from './Camps';
import Activities from './Activities';
import Providers from './Providers';
import Promotions from './Promotions';
import Trainnings from './Trainnings';
import Programs from './Programs';

import { sideChagen } from "../redux/actions/sideActions";
import Register from './Register';


import IdleTimer from 'react-idle-timer'
import Modal from 'react-responsive-modal';

const Main: React.FC = () => {
  const mStyles = {
    modal: {
      maxWidth: '18rem'
    },
    closeButton: {
      display: "none"
    }
  }
  const mClases = {
    modal: "card border-dark"
  }

  const [menu, setMenu] = useState(INI_MENU);
  const [actions, setActions] = useState(INI_MENU);
  const [crumbs, setCrumbs] = useState(window.location.pathname.split('/'));
  const [modInactivity, setModInactivity] = useState(false);
  const [idleTimer, setIdleTimer] = useState()
  const [idleStart, setIdleStart] = useState()

  const dispatch = useDispatch();
  const ActiveSide = (display: boolean) => dispatch ( sideChagen(display) )

  const login = useSelector((state: any) => {
    return state.login; 
  });

  const user = useSelector((state: any) => {
    return state.user;
  });

  const sideBar = useSelector((state: any) => {
    return state.sidebar;
  });

  useEffect(() => {
    let c = ['home'];
    window.location.pathname.split('/').map(el => {
      if (el.length > 0 && el !== 'home') {
        c.push(el);
      }
      return true;
    });

    setCrumbs(c);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[window.location.pathname]);

  useEffect(() => {
    let objMenu: _MENU = [
      {name: 'Registro', link:'/home/register', icon: 'fa-database', show: true},
      {name: 'Dashboard', link:'/home/dashboard', icon: 'fa-windows', show: true},
      {name: 'Punto de Venta', link:'/home/pos', icon: 'fa-shopping-basket', show: true},
      {name: 'Directorio', link:'/home/directory', icon: 'fa-address-book', show: true},
      {name: 'Productos', link:'/home/products', icon: 'fa-product-hunt', show: true},
      {name: 'Archivos', link:'/home/archives', icon: 'fa-archive', show: true},
      {name: 'Artes Campaña', link:'/home/camps', icon: 'fa-file-image-o', show: true},
      {name: 'Actividades', link:'/home/activities', icon: 'fa-heartbeat', show: true},
      {name: 'Proveedores', link:'/home/providers', icon: 'fa-id-badge', show: true},
      {name: 'Promociones', link:'/home/promotions', icon: 'fa-ticket', show: true},
      {name: 'Capacitación', link:'/home/trainnings', icon: 'fa-graduation-cap', show: true},
      {name: 'Programa', link:'/home/programs', icon: 'fa-calendar-check-o', show: true}
    ];

    let objActions = [
      {name: 'Imprimir', link:'/home/print', icon: 'fa-print', show: true},
      {name: 'Compartir', link:'/home/share', icon: 'fa-share-alt', show: true},
    ];

    setMenu(objMenu);
    setActions(objActions);
  },[user]);

  if (login.validAccess === false) {
    console.log('Access Deny');
    window.location.href = `/`;
  }

  const HideSideBar = () => {
    if (sideBar.display){
      ActiveSide(false);
    }
  }

  /** Activity Session */
  const onCloseInactivity = () => {
    setModInactivity(false);
    window.location.href = `/logout`;
  }

  const _onAction = (e: any) => {
    // console.log('user did something', e)
  }

  const _onActive = (e: any) => {
    // console.log('user is active', e)
    // console.log('time remaining', idleTimer.getRemainingTime())
  }

  const _onIdle = (e:any) => {
    let time = idleTimer.getLastActiveTime()
    let realTime = new Date(time * 1000);
    console.log(realTime);
    setModInactivity(true);
    if (login.validAccess === true) {
      setIdleStart(realTime.getTime())
    } else {
      console.log("No hay session")
    }
  // console.log('user is idle', e)
  // console.log('last active', idleTimer.getLastActiveTime())
}

  return (
    <IonPage>
      <IonHeader>
        <ToolBar />
      </IonHeader>
      <IonContent onClick={HideSideBar}>
        <Fragment>
          <div className="wrapper fixed-nav">
            <SideBar menu={menu} actions={actions} />
            <div className="main">
              <BreadCrumb crumbs={crumbs} />
              <Switch>
                <Route path="/home" component={Home} exact={true} />
                <Route path="/home/dashboard" component={Home} exact={true} />
                <Route path="/home/pos" component={Pos} exact={true} />
                <Route path="/home/directory" component={AddressBook} exact={true} />
                <Route path="/home/products" component={Products} exact={true} />
                <Route path="/home/archives" component={Archives} exact={true} />
                <Route path="/home/camps" component={Camps} exact={true} />
                <Route path="/home/activities" component={Activities} exact={true} />
                <Route path="/home/providers" component={Providers} exact={true} />
                <Route path="/home/promotions" component={Promotions} exact={true} />
                <Route path="/home/trainnings" component={Trainnings} exact={true} />
                <Route path="/home/programs" component={Programs} exact={true} />
                <Route path="/home/register/" component={Register} exact={true} />
                <Route component={NotFound} />
              </Switch>
              <IdleTimer 
                ref={ref => { setIdleTimer(ref) }}
                element={document}
                onActive={_onActive}
                onIdle={_onIdle}
                onAction={_onAction}
                debounce={250}
                timeout={600000}
              />
              <Modal open={modInactivity} onClose={onCloseInactivity} center classNames={mClases} styles={mStyles}>
                <div className="card-header">Sesión Terminada</div>
                <br />
                <div className="card-body text-dark">
                  <h5 className="card-title">Inactividad</h5>
                  <p className="card-text">Tu sesión ha expirado por inactividad {idleStart}.</p>
                </div>
                <br /><br /><br />
                <div className="form-inline">
                    <button className="btn btn-primary" onClick={onCloseInactivity}>Cerrar</button>
                </div>
              </Modal>
            </div>
          </div>
        </Fragment>
      </IonContent>
    </IonPage>
  );
};

export default Main;
