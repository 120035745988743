import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { createUserAction } from '../../redux/actions/userActions';
import Modal from 'react-responsive-modal';
import { deleteUserAction } from '../../redux/actions/userActions';
import Loading from '../Loading';
// import Toast from '../Toast';

const DeleteUser: React.FC<{modDel: any, onCloseDel: any, setModDel: any, user: any}> = (props) => {
    const mClases = {
        modal: "card border-dark"
    }

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [me, setMe] = useState('');
    const [meId, setMeId] = useState('');
    const [process, setProcess] = useState(false);

    const dispatch = useDispatch();
    const deleteUser = (userObj: any) => { dispatch( deleteUserAction(userObj) )};

    const user = useSelector((state: any) => {
        return state.user;
      });
    

    useEffect(() => {
        setOpen(props.modDel);
        if(props.user === undefined) {
            return;
        }
        setMe(user.perfil.user.email);
        setMeId(user.perfil.user.id);
        setEmail(props.user.email);
        setUserId(props.user.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.modDel])

    const SendDeleteUser = () => {
        setProcess(true);
        let userObj = {
            who: {
                id: meId
            },
            id: userId,
            email: email
        }
        
        deleteUser(userObj);

        const waitResult = setTimeout(() => {
            setOpen(false);
            props.setModDel(false);
            props.onCloseDel();
            setProcess(false);
        }, 2000);

        return () => waitResult;
    }

    const closeDelete = () => {
        setOpen(false);
        props.setModDel(false);
    }

    return (
        <Modal open={open} onClose={props.onCloseDel} center classNames={mClases} showCloseIcon={false} closeOnOverlayClick={false}>

            <div className="card-header">Borrar Usuario</div>
            <br />
            {me === email ? 
                (
                <div className="card-body text-dark">
                    <div className="card-body text-dark">
                        <p className="card-text">No puede borrar su propio usuario.</p>
                        <br /><br />
                        <div className="content-box-footer col-md-12 sm-max sm-text-center">
                            <button className="btn btn-dark sm-max col-12" onClick={closeDelete}>
                                <i className="fa fa-close"></i> Cancelar
                            </button>
                        </div>
                    </div>
                </div>
                )
            : process ? (<Loading message="Procesando..." />) :
                (
                <div className="card-body text-dark">
                    <div className="card-body text-dark">
                        <p className="card-text">Confirma que desea borrar el usuario {email} - {userId}.</p>
                        <br /><br />

                        <div className="content-box-footer col-md-12 sm-max sm-text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-pdv-process" onClick={SendDeleteUser}>
                                        <i className="fa fa-trash"></i> Borrar
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    <button className="btn btn-pdv-cancel" onClick={closeDelete}>
                                        <i className="fa fa-close"></i> Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
            }
        </Modal>
    );
}

export default DeleteUser;