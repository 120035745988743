import axios from 'axios';

const req = axios.create({
    baseURL : 'https://pdv.back-end.com.mx/api/v1/',
    headers: {'Content-Type': 'application/json', 'apikey': 'feecf5d1-4a2d-405e-9959-4d8ec82bd61e'},
})


req.interceptors.response.use(response => response, error => {
    if(!error.response && window.location.pathname !== '/'){
        window.location.href = "/logout";
    }
    if(error.response.status === 402 && window.location.pathname !== '/'){
        window.location.href = "/logout";
        return;
    }
    return Promise.reject(error);
});



export default req;