import { LOGIN_INIT, LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_LOGOUT } from '../types';
import { deleteStorage } from '../../localStorage';
import Toast from '../../components/Toast';
import axios from '../../config/axio';

export const loginInit = () => {
    return { type : LOGIN_INIT };
};
export const loginSuccess = (login) => {
    return { type: LOGIN_SUCCESS, payload : login };
};
export const loginError = (error) => {
    console.log(error);
    return { type : LOGIN_ERROR, payload: error };
};
export const loginLogout = () => {
    return { type : LOGIN_LOGOUT, payload :{ }};
};

/**
 * logoutAccion
 * @param {*} loginObj 
 */
export const logoutAccion = (loginObj) => {
    return dispatch => {

        const options = {
            headers: {'Content-Type': 'application/json'}
        };

        axios.post('/LOGOUT/user', loginObj, options).then(result => {
            console.log({...result.data});
            if (result.data.error){
                dispatch(loginError(result.data.message))
                Toast.fire(
                    {
                        type: 'error',
                        title: result.data.message
                    }
                );
            } else {

                dispatch( loginLogout() );
                deleteStorage();
            }
        }).catch(error => {
            dispatch( loginError(error));
            console.log(error);
        });

    };
};

/**
 * cleanLogintAccion
 */
export const cleanLogintAccion = () => {
    return dispatch => {
        dispatch( loginLogout() );
        deleteStorage();
    };
};

/**
 * loginAction
 * @param {*} loginObj 
 */
export const loginAction = (loginObj) => {
    return dispatch => {
        dispatch( loginInit());

        const options = {
            headers: {'Content-Type': 'application/json'}
        };

        axios.post('/LOGIN/user', loginObj, options).then(result => {
            console.log({...result.data});
            if (result.data.error){
                dispatch(loginError(result.data.message))
                Toast.fire(
                    {
                        type: 'error',
                        title: result.data.message
                    }
                );
            } else {
                dispatch (loginSuccess(result.data))
            }
        }).catch(error => {
            dispatch( loginError(error));
            console.log(error);
        });

    };
};
