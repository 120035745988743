import { SIDE_SHOW, SIDE_HIDE, SIDE_ERROR } from '../types';

export const sideHide = () => {
    return { type : SIDE_HIDE };
};
export const sideShow = (login) => {
    return { type: SIDE_SHOW };
};
export const sideError = (error) => {
    console.log(error);
    return { type : SIDE_ERROR };
};

export const sideChagen = (display) => {
    return dispatch => {
        console.log(display);
        if (display === true) {
            dispatch( sideShow() );
        } else if (display === false) {
            dispatch( sideHide() )
        } else {
            dispatch( sideError() )
        }
    };
};

