import React, { useState, useEffect } from "react";
import NewUser from "./user/NewUser";
import DeleteUser from "./user/DeleteUser";

const ListUsers: React.FC<{list: any}> = (props) =>  {
  const [modAdd, setModAdd] = useState(false);
  const [modDel, setModDel] = useState(false);
  const [person, setPerson] = useState();

  useEffect(() => {
      // code
   },[props.list])

  const onCloseAdd = () => {
    setModAdd(false);
    window.location.reload(false);
  }
  const onCloseDel = () => {
    setModDel(false);
    window.location.reload(false);
  }
  
  const openDelete = (item: any) => {
    // console.log(item);
    setPerson(item)
    setModDel(true);
  }
  

  return (
    <div className="row">
    <div className="col-md-12">
        <div className="content-box">
            <div className="content-box-header">
                <div className="row">
                    <div className="col-md-6 sm-max sm-text-center">
                        <button className="btn btn-primary sm-max pos-btn" onClick={e => setModAdd(true)}><i className="fa fa-plus-circle"></i> Agregar usuario</button>
                    </div>
                    <div className="col-md-6 sm-max sm-text-center form-inline justify-content-end">
                        <select className="form-control mb-1 mr-sm-1 mb-sm-0 sm-max sm-mgtop-5">
                            <option>Nombre</option>
                            <option>Correo Electrónico</option>
                            <option>Estado</option>
                        </select>
                        <input className="form-control sm-max sm-mgtop-5" placeholder="Search" type="text" />
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th className="text-center"><input type="checkbox" /></th>
                            <th className="text-center">Nombre</th>
                            <th className="text-center">Correo Electrónico</th>
                            <th className="text-center">Estado</th>
                            <th className="text-center">Rol</th>
                            <th className="text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    props.list.list.length === undefined ? null :
                    props.list.list.map((person: any, index: any) => {
                        return(
                            <tr key={index}>
                                <th className="text-center"><input type="checkbox" /></th>
                                <td className="nowrap">{`${person.name} ${person.middle_name} ${person.last_name}`}</td>
                                <td className="nowrap">{`${person.email}`}</td>
                                <td className="nowrap">
                                    
                                    <div className={`status-pill ${person.active === '1' ? 'green' : 'yellow'}`}></div>
                                </td>
                                <td className="nowrap">{`${person.rol}`}</td>
                                <td className="text-center">
                                    <i className="fa fa-pencil clickeable icon-space"></i>
                                    <i className="fa fa-trash clickeable" onClick={e => openDelete(person)}></i>
                                </td>
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className="content-box-footer">
                <div className="row">
                    <div className="col-md-3 form-inline sm-max sm-text-center">
                        <select className="form-control sm-max">
                            <option>5</option>
                        </select>
                    </div>
                    <div className="col-md-3 form-inline sm-max sm-mgtop-5 sm-text-center">Mostrar 10 de 100</div>
                    <div className="col-md-6 sm-max">
                        <ul className="pagination pull-right sm-mgtop-5">
                            <li><a href="/cahnge">Inicio</a></li>
                            <li><a href="/cahnge">Anterior</a></li>
                            <li><a href="/cahnge">1</a></li>
                            <li className="active"><a href="/cahnge">2</a></li>
                            <li><a href="/cahnge">3</a></li>
                            <li><a href="/cahnge">Siguiente</a></li>
                            <li><a href="/cahnge">Último</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <NewUser modAdd={modAdd} onCloseAdd={onCloseAdd} setModAdd={setModAdd}/>
        <DeleteUser modDel={modDel} onCloseDel={onCloseDel} setModDel={setModDel} user={person}/>
    </div>
</div>
  );
};

export default ListUsers;
