import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { _MENU } from "../utils/types"

const SideBar: React.FC<{menu: _MENU, actions: _MENU}> = (props) => {
  const sideBar = useSelector((state: any) => {
    return state.sidebar
  })


  useEffect(() => {
    console.log(sideBar, typeof sideBar)
  },[sideBar])

  return (
    <aside className={`side-nav nav-scroll ${sideBar.display === true ? "display-any": ""}`}>
        <div className="main-menu-title">Menu</div>
        <div className="main-menu">
            <ul>
              {props.menu.map((el, index) => {
                return el.show === false ? null :
                  (
                  <li key={index}>
                    <NavLink to={el.link}>
                      <i className={`fa ${el.icon}`}></i> 
                      <span>{el.name}</span>                  
                    </NavLink>
                  </li>
                  )
                })
              }
            </ul>
        </div>
        <div className="main-menu-title">Acciones</div>
        <div className="main-menu">
            <ul>
            {props.actions.map((el, index) => {
                return el.show === false ? null : 
                  (
                  <li key={index}>
                    <NavLink to={el.link}>
                      <i className={`fa ${el.icon}`}></i> 
                      <span>{el.name}</span>                  
                    </NavLink>
                  </li>
                  )
                })
              }
            </ul>
        </div>
    </aside>
  );
};

export default SideBar;
