import axios from '../../config/axio';

import { USERS_INIT, USERS_LIST, USERS_SELECTED, USERS_ERROR } from '../types';
// import axios from '../../config/axios';
// import { dummy_users } from '../../utils/dummy';

export const usersInitilize = () => {
    return { type: USERS_INIT };
};
export const usersList = (usersObject) => {
    return { type : USERS_LIST, payload : usersObject};
};
export const usersError = () => {
    return { type:  USERS_ERROR };
};

export const userSelected = () => {
    return { type: USERS_SELECTED }
}

export const getUserList = () => {
    return (dispatch, getState) => {
        dispatch(usersInitilize());

        const { token, rol, id } = getState().login.login_info;
        console.log(rol, id);

        const options = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : `Bearer ${token}`
            }
        };

        axios.get(`/GET/users`, options).then (result => {
            
            dispatch(usersList(result.data));

        }).catch(error => {
            console.log(error);
            dispatch(usersError());
        });

    };
};
