export const INI_MENU = [{name: '', link: '', icon: '', show: false}];
export const INI_PERFIL = {name: '', email: '', rol: '', image: ''};
export const INI_USERS = [{
    status: 0,
    rol: '',
    token: '',
    brand: '',
    logo: '',
    user: {
        name: '',
        email: '',
        photo: ''
    }
}]
