import { combineReducers } from 'redux'
import loginReducer from './loginReducer';
import userReducer from './userReducer';
import sideReducer from './sideReducer';
import usersReducer from './usersReducer';

export default combineReducers({
    login : loginReducer,
    user: userReducer,
    sidebar: sideReducer,
    users: usersReducer
});