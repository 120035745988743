import React, { useState, useEffect} from 'react';
import '../assets/css/loading.css';

interface LoadingProps {
    message?: string;
}

const Loading: React.FC<LoadingProps> = (props) => {
    const [ message , setMessage] = useState();

    useEffect(() => {
        if(props.message !== undefined && props.message !== null){
            setMessage(props.message);
        } else {
            setMessage("Loading...")
        }
    },
    [props.message, message]
    );

    return (
        <>
            <p>{message}</p>
            <div className="loader"></div>
        </>
    );
}

export default Loading;