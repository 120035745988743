import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Home: React.FC = () => {
  const login = useSelector((state: any) => {
    return state.login; 
  });

  const user = useSelector((state: any) => {
    return state.user;
  });

  useEffect(() => {
    console.log(login.login_info.token)
    console.log(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[login]);

  return (
    <div className="content">
        <div className="panel">
            <div className="content-header no-mg-top">
                <i className="fa fa-newspaper-o"></i>
                <div className="content-header-title">Dashboard</div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-box">
                      Dashboard
                    </div>
                </div>
            </div>
        </div>
    </div>

  );
};

export default Home;
